import { Benefit } from "@/types/general";

export const PHONE_CONFIG: {
  [key: string]: { prefix: string; adjustments?: any; regex: RegExp };
} = {
  arg: {
    prefix: "549",
    adjustments: (number: string) => {
      if (number.length === 8) {
        return `11${number}`;
      } else if (number.startsWith("15")) {
        return `11${number.slice(2)}`;
      }
      return number;
    },
    regex: /^(549)(?:11|[2368]\d)\d{8}$/,
  },
  pry: {
    prefix: "595",
    regex: /^(5959)([6-9][1-6])\d{6,7}$/,
  },
  ury: {
    prefix: "598",
    regex: /^(598)(9)([1-9])\d{6}$/,
  },
  col: {
    prefix: "57",
    regex: /^(57)(3[0-9][0-9])\d{7}$/,
  },
  per: {
    prefix: "51",
    regex: /^(51)(9)\d{8}$/,
  },
  chl: {
    prefix: "56",
    regex: /^(56)(9)\d{8}$/,
  },
  ven: {
    prefix: "58",
    regex: /^(58)(?:(41[245678])|(42[46]))\d{7}$/,
  },
  ecu: {
    prefix: "593",
    regex: /^(593)(9)\d{8}$/,
  },
  bol: {
    prefix: "591",
    regex: /^(591)([6-7])\d{7}$/,
  },
  esp: {
    prefix: "34",
    regex: /^(34)(?:(6)|(7))\d{8}$/,
  },
  default: {
    prefix: "549",
    regex: /^(549)(?:11|[2368]\d)\d{8}$/,
  },
};

export const PREFIX_DATA = [
  { id_prefix: 1, value: "Dr.", description: "Dr." },
  { id_prefix: 2, value: "Dra.", description: "Dra." },
];
export const TAXES_CONDITION = [
  {
    id_tax_condition: 1,
    value: "MO",
    description: "Monotributista",
  },
  {
    id_tax_condition: 2,
    value: "RI",
    description: "Responsable Inscripto",
  },
  { id_tax_condition: 3, value: "EX", description: "Excento" },
];

export const TYPES_LICENCES = [
  { id_licence_type: 1, value: "MN", description: "Nacional" },
  { id_licence_type: 2, value: "MP", description: "Provincial" },
];

export const DAYS = [
  { id_day: 1, value: "Lunes" },
  { id_day: 2, value: "Martes" },
  { id_day: 3, value: "Miércoles" },
  { id_day: 4, value: "Jueves" },
  { id_day: 5, value: "Viernes" },
  { id_day: 6, value: "Sábado" },
  { id_day: 7, value: "Domingo" },
];
export const MEDICAL_INSURANCES_TABS = (disabled: boolean) => [
  {
    text: "Datos",
    value: "data",
    disabled: false,
  },
  {
    text: "Planes",
    value: "plans",
    disabled,
  },
  {
    text: "Grupos",
    value: "groups",
    disabled,
  },
  {
    text: "Prestaciones",
    value: "benefits",
    disabled,
  },
];
export const PROFESSIONALS_TABS = (disabled: boolean) => [
  {
    text: "Datos",
    value: "general-data",
    disabled: false,
  },
  {
    text: "Horarios",
    value: "times",
    disabled,
  },
  {
    text: "Ausencias",
    value: "abscences",
    disabled,
  },
];
export const LABORATORY_TABS = (disabled: boolean) => [
  {
    text: "Datos",
    value: "general-data",
    disabled: false,
  },
  {
    text: "Lista de precios",
    value: "price-list",
    disabled,
  },
];
export const BENEFITS_GEIST_DATA: Benefit[] = [
  {
    id_benefit: 0,
    id_plan: 0,
    benefit_code: "",
    code_benefit_med_ins_liq: "",
    description: "",
    amount_mi: 0,
    amount_patient: 0,
    drawing: "",
    tooth: "",
    requires_face: false,
    internal: false,
    disabled: false,
    unit: "",
    quantity: 0,
    is_odontogram: false,
    age: "",
    per_patient: 0,
    per_piece_sector: 0,
    id_group: null,
    id_benefit_to_be_performed: null,
    id_specialty: null,
    rowversion: "",
    is_usd: false,
    id_specialty_navigation: null,
  },
  {
    id_benefit: 0,
    id_plan: 0,
    benefit_code: "",
    code_benefit_med_ins_liq: "",
    description: "",
    amount_mi: 0,
    amount_patient: 0,
    drawing: "",
    tooth: "",
    requires_face: false,
    internal: false,
    disabled: false,
    unit: "",
    quantity: 0,
    is_odontogram: false,
    age: "",
    per_patient: 0,
    per_piece_sector: 0,
    id_group: null,
    id_benefit_to_be_performed: null,
    id_specialty: null,
    rowversion: "",
    is_usd: false,
    id_specialty_navigation: null,
  },
  {
    id_benefit: 0,
    id_plan: 0,
    benefit_code: "",
    code_benefit_med_ins_liq: "",
    description: "",
    amount_mi: 0,
    amount_patient: 0,
    drawing: "",
    tooth: "",
    requires_face: false,
    internal: false,
    disabled: false,
    unit: "",
    quantity: 0,
    is_odontogram: false,
    age: "",
    per_patient: 0,
    per_piece_sector: 0,
    id_group: null,
    id_benefit_to_be_performed: null,
    id_specialty: null,
    rowversion: "",
    is_usd: false,
    id_specialty_navigation: null,
  },
  {
    id_benefit: 0,
    id_plan: 0,
    benefit_code: "",
    code_benefit_med_ins_liq: "",
    description: "",
    amount_mi: 0,
    amount_patient: 0,
    drawing: "",
    tooth: "",
    requires_face: false,
    internal: false,
    disabled: false,
    unit: "",
    quantity: 0,
    is_odontogram: false,
    age: "",
    per_patient: 0,
    per_piece_sector: 0,
    id_group: null,
    id_benefit_to_be_performed: null,
    id_specialty: null,
    rowversion: "",
    is_usd: false,
    id_specialty_navigation: null,
  },
  {
    id_benefit: 0,
    id_plan: 0,
    benefit_code: "",
    code_benefit_med_ins_liq: "",
    description: "",
    amount_mi: 0,
    amount_patient: 0,
    drawing: "",
    tooth: "",
    requires_face: false,
    internal: false,
    disabled: false,
    unit: "",
    quantity: 0,
    is_odontogram: false,
    age: "",
    per_patient: 0,
    per_piece_sector: 0,
    id_group: null,
    id_benefit_to_be_performed: null,
    id_specialty: null,
    rowversion: "",
    is_usd: false,
    id_specialty_navigation: null,
  },
  {
    id_benefit: 0,
    id_plan: 0,
    benefit_code: "",
    code_benefit_med_ins_liq: "",
    description: "",
    amount_mi: 0,
    amount_patient: 0,
    drawing: "",
    tooth: "",
    requires_face: false,
    internal: false,
    disabled: false,
    unit: "",
    quantity: 0,
    is_odontogram: false,
    age: "",
    per_patient: 0,
    per_piece_sector: 0,
    id_group: null,
    id_benefit_to_be_performed: null,
    id_specialty: null,
    rowversion: "",
    is_usd: false,
    id_specialty_navigation: null,
  },
  {
    id_benefit: 0,
    id_plan: 0,
    benefit_code: "",
    code_benefit_med_ins_liq: "",
    description: "",
    amount_mi: 0,
    amount_patient: 0,
    drawing: "",
    tooth: "",
    requires_face: false,
    internal: false,
    disabled: false,
    unit: "",
    quantity: 0,
    is_odontogram: false,
    age: "",
    per_patient: 0,
    per_piece_sector: 0,
    id_group: null,
    id_benefit_to_be_performed: null,
    id_specialty: null,
    rowversion: "",
    is_usd: false,
    id_specialty_navigation: null,
  },
  {
    id_benefit: 0,
    id_plan: 0,
    benefit_code: "",
    code_benefit_med_ins_liq: "",
    description: "",
    amount_mi: 0,
    amount_patient: 0,
    drawing: "",
    tooth: "",
    requires_face: false,
    internal: false,
    disabled: false,
    unit: "",
    quantity: 0,
    is_odontogram: false,
    age: "",
    per_patient: 0,
    per_piece_sector: 0,
    id_group: null,
    id_benefit_to_be_performed: null,
    id_specialty: null,
    rowversion: "",
    is_usd: false,
    id_specialty_navigation: null,
  },
  {
    id_benefit: 0,
    id_plan: 0,
    benefit_code: "",
    code_benefit_med_ins_liq: "",
    description: "",
    amount_mi: 0,
    amount_patient: 0,
    drawing: "",
    tooth: "",
    requires_face: false,
    internal: false,
    disabled: false,
    unit: "",
    quantity: 0,
    is_odontogram: false,
    age: "",
    per_patient: 0,
    per_piece_sector: 0,
    id_group: null,
    id_benefit_to_be_performed: null,
    id_specialty: null,
    rowversion: "",
    is_usd: false,
    id_specialty_navigation: null,
  },
] as const;

export const BENEFIT_OPTIONS = {
  ageField: [
    { id: "none", text: "Todas" },
    { id: "major", text: "Mayor a" },
    { id: "minor", text: "Menor a" },
  ],
  unitField: [
    { id: "none", text: "Ninguno" },
    { id: "year", text: "Año" },
    { id: "month", text: "Mes" },
    { id: "day", text: "Día" },
  ],
  toothField: [
    { id: "NIN", text: "No pide" },
    { id: "DIE", text: "Pide uno" },
    { id: "ALG", text: "Pide algunos" },
    { id: "SUP", text: "Completa superior" },
    { id: "INF", text: "Completa inferior" },
  ],
};
