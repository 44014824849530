"use client";

import React from "react";
import { GEIST_DATA } from "@/libs/administration";
import { BENEFITS_GEIST_DATA } from "@/libs/config";
import { DEFAULT, BENEFITS, CONFIG } from "./columns";
import { Data } from "./data";

export const Default: React.FC = () => (
  <Data columns={DEFAULT} data={GEIST_DATA} />
);

export const Benefits: React.FC = () => (
  <Data columns={BENEFITS} data={BENEFITS_GEIST_DATA} />
);

export const Config: React.FC = () => (
  <Data columns={CONFIG} data={BENEFITS_GEIST_DATA} />
);
