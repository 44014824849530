import React from "react";
import { Skeleton } from "@/components/atoms";

export const DEFAULT = [
  {
    accessorKey: "patient",
    header: "Paciente",
    cell: () => <Skeleton className="h-5 w-[150px] rounded" />,
  },
  {
    accessorKey: "voucher",
    header: "Comprobante",
    cell: () => <Skeleton className="h-5 w-[100px] rounded" />,
  },
  {
    accessorKey: "typeNumber",
    header: "Tipo - N°",
    cell: () => <Skeleton className="h-5 w-[200px] rounded" />,
  },
  {
    accessorKey: "method",
    header: "Forma",
    cell: () => <Skeleton className="h-5 w-[100px] rounded" />,
  },
  {
    accessorKey: "professional",
    header: "Profesional",
    cell: () => <Skeleton className="h-5 w-[200px] rounded" />,
  },
  {
    accessorKey: "owed",
    header: "Adeuda",
    cell: () => <Skeleton className="h-5 w-[70px] rounded" />,
  },
  {
    accessorKey: "paid",
    header: "Pagado",
    cell: () => <Skeleton className="h-5 w-[70px] rounded" />,
  },
  {
    accessorKey: "obs",
    header: "Observaciones",
    cell: () => <Skeleton className="h-5 w-[170px] rounded" />,
  },
  {
    accessorKey: "methodDetail",
    header: "Detalles de forma",
    cell: () => <Skeleton className="h-5 w-[170px] rounded" />,
  },
  {
    accessorKey: "office",
    header: "Sucursal",
    cell: () => <Skeleton className="h-5 w-[70px] rounded" />,
  },
  {
    accessorKey: "user",
    header: "Usuario",
    cell: () => <Skeleton className="h-5 w-[70px] rounded" />,
  },
];

export const CONFIG = [
  {
    accessorKey: "patient",
    header: () => <Skeleton className="h-5 w-[90px] rounded" />,
    cell: () => <Skeleton className="h-5 w-[150px] rounded" />,
  },
  {
    accessorKey: "voucher",
    header: () => <Skeleton className="h-5 w-[90px] rounded" />,
    cell: () => <Skeleton className="h-5 w-[100px] rounded" />,
  },
  {
    accessorKey: "typeNumber",
    header: () => <Skeleton className="h-5 w-[90px] rounded" />,
    cell: () => <Skeleton className="h-5 w-[200px] rounded" />,
  },
  {
    accessorKey: "method",
    header: () => <Skeleton className="h-5 w-[90px] rounded" />,
    cell: () => <Skeleton className="h-5 w-[100px] rounded" />,
  },
  {
    accessorKey: "professional",
    header: () => <Skeleton className="h-5 w-[90px] rounded" />,
    cell: () => <Skeleton className="h-5 w-[200px] rounded" />,
  },
  {
    accessorKey: "owed",
    header: () => <Skeleton className="h-5 w-[90px] rounded" />,
    cell: () => <Skeleton className="h-5 w-[70px] rounded" />,
  },
  {
    accessorKey: "paid",
    header: () => <Skeleton className="h-5 w-[90px] rounded" />,
    cell: () => <Skeleton className="h-5 w-[70px] rounded" />,
  },
  {
    accessorKey: "obs",
    header: () => <Skeleton className="h-5 w-[90px] rounded" />,
    cell: () => <Skeleton className="h-5 w-[170px] rounded" />,
  },
  {
    accessorKey: "methodDetail",
    header: () => <Skeleton className="h-5 w-[90px] rounded" />,
    cell: () => <Skeleton className="h-5 w-[170px] rounded" />,
  },
  {
    accessorKey: "office",
    header: () => <Skeleton className="h-5 w-[90px] rounded" />,
    cell: () => <Skeleton className="h-5 w-[70px] rounded" />,
  },
  {
    accessorKey: "user",
    header: () => <Skeleton className="h-5 w-[90px] rounded" />,
    cell: () => <Skeleton className="h-5 w-[70px] rounded" />,
  },
];

export const BENEFITS = [
  {
    id: "actions",
    enableHiding: false,
    cell: () => <Skeleton className="animate-pulse  h-5 w-[150px] rounded" />,
  },
  {
    accessorKey: "description",
    header: "Prestación",
    enableHiding: false,
    cell: () => <Skeleton className="animate-pulse  h-5 w-[150px] rounded" />,
  },
  {
    accessorKey: "is_usd",
    header: "En USD",
    enableHiding: false,
    cell: () => <Skeleton className="animate-pulse  h-5 w-[150px] rounded" />,
  },
  {
    accessorKey: "amount_mi",
    header: "Importe OS",
    enableHiding: false,
    cell: () => <Skeleton className="animate-pulse  h-5 w-[150px] rounded" />,
  },
  {
    accessorKey: "amount_patient",
    header: "Importe paciente",
    enableHiding: false,
    cell: () => <Skeleton className="animate-pulse  h-5 w-[150px] rounded" />,
  },
];
