import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import { DailyCashflowData } from "@/types/administration";

export const tabs: { text: string; value: string; href: string }[] = [
  {
    text: "Caja diaria",
    value: "daily-cashflow",
    href: "/dashboard/administration/dailycashflow",
  },
  {
    text: "Movimientos",
    value: "transactions",
    href: "/dashboard/administration/transactions",
  },
  {
    text: "Bancos",
    value: "banks",
    href: "/dashboard/administration/dailycashflow",
  },
  {
    text: "Proveedores",
    value: "suppliers",
    href: "/dashboard/administration/dailycashflow",
  },
  {
    text: "Tarjetas",
    value: "cards",
    href: "/dashboard/administration/dailycashflow",
  },
];

export const dailyCashflowTabs: { text: string; value: string; icon?: any }[] =
  [
    {
      text: "Pagos de pacientes",
      value: "patient_payments",
    },
    {
      text: "Otros ingresos",
      value: "incomes",
    },
    {
      text: "Egresos varios",
      value: "expenses",
    },
  ];

export const INITAL_PARAMS = {
  DAILY_CASHFLOW: {
    date_from: new TZDate(new Date().toISOString(), TIMEZONES.AR),
    id_user: null,
    id_office: null,
    all_users: true,
    calculate_in_usd: false,
    ignore_registers: false,
  },
  TRANSACTIONS: {
    date_from: new TZDate(new Date().toISOString(), TIMEZONES.AR),
    date_to: new TZDate(new Date().toISOString(), TIMEZONES.AR),
    id_user: null,
    id_office: null,
    all_users: true,
    all_offices: false,
    calculate_in_usd: false,
    ignore_registers: false,
  },
  MOVEMENTS: {
    date_from: new TZDate(new Date().toISOString(), TIMEZONES.AR),
    date_to: new TZDate(new Date().toISOString(), TIMEZONES.AR),
    id_bank: null,
  },
  SUPPLIERS: {
    date_from: new TZDate(new Date().toISOString(), TIMEZONES.AR),
    date_to: new TZDate(new Date().toISOString(), TIMEZONES.AR),
    id_supplier: null,
  },
};

export const GEIST_DATA: DailyCashflowData[] = [
  {
    id_patient: 17500,
    patient: "HURTADO DANIELA",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 100,
    iva: 0,
    obervation: "",
    id: 16608,
    date: "20/09/2024",
    voucher: "Factura",
    type_number: "A",
    method: "Transf.",
    method_detail: "GALICIA",
    concept: "",
    is_canceled: false,
    is_usd: false,
    user: "rosa.rojas",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 600,
    paid: 0,
    iva: 0,
    obervation: "",
    id: 16606,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "Otro",
    method_detail: "",
    concept: "PAGO ADEUDADO OTRO CON CAJA",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 1200,
    paid: 0,
    iva: 0,
    obervation: "",
    id: 16605,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "Efectivo",
    method_detail: "",
    concept: "PAGO ADEUDADO EFECTIVO",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 3500,
    iva: 0,
    obervation: "",
    id: 16604,
    date: "20/09/2024",
    voucher: "Nota Crédito",
    type_number: "B",
    method: "Transf.",
    method_detail: "MERCADO PAGO",
    concept: "PAGO NC TRANSFERENCIA",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 3500,
    iva: 0,
    obervation: "",
    id: 16600,
    date: "20/09/2024",
    voucher: "Nota Crédito",
    type_number: "B",
    method: "Efectivo",
    method_detail: "",
    concept: "PAGO NC EFECTIVO",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 7000,
    iva: 0,
    obervation: "",
    id: 16592,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "Cheque",
    method_detail: "EJEMPLO - 20/09/2024 - 010101",
    concept: "PAGO CHEQUE",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 6000,
    iva: 0,
    obervation: "",
    id: 16591,
    date: "20/09/2024",
    voucher: "Factura",
    type_number: "B",
    method: "Transf.",
    method_detail: "MERCADO PAGO",
    concept: "PAGO TRANSFERENCIA",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 5000,
    iva: 0,
    obervation: "",
    id: 16590,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "TarjetaD",
    method_detail: "DEBITO - 01010101",
    concept: "PAGO TDD",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 4000,
    iva: 0,
    obervation: "",
    id: 16588,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "Tarjeta",
    method_detail: "AMEX - 01010101",
    concept: "PAGO TDC",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 3000,
    iva: 0,
    obervation: "",
    id: 16587,
    date: "20/09/2024",
    voucher: "Factura",
    type_number: "B",
    method: "Otro",
    method_detail: "",
    concept: "PAGO OTRO CON CAJA",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 1000,
    iva: 0,
    obervation: "",
    id: 16585,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "Efectivo",
    method_detail: "",
    concept: "PAGO EN EFECTIVO",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 17500,
    patient: "HURTADO DANIELA",
    med_ins: "PRIVADOS",
    professional: "CORTIZO FERNANDEZ AGUSTINA MARIA",
    owed: 0,
    paid: 80000,
    iva: 0,
    obervation: "",
    id: 16577,
    date: "20/09/2024",
    voucher: "Factura",
    type_number: "A",
    method: "Efectivo",
    method_detail: "",
    concept: "PERNO PD 11",
    is_canceled: false,
    is_usd: false,
    user: "rosa.rojas",
    office: null,
    type: "example",
  },
  {
    id_patient: 17500,
    patient: "HURTADO DANIELA",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 100,
    iva: 0,
    obervation: "",
    id: 16608,
    date: "20/09/2024",
    voucher: "Factura",
    type_number: "A",
    method: "Transf.",
    method_detail: "GALICIA",
    concept: "",
    is_canceled: false,
    is_usd: false,
    user: "rosa.rojas",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 600,
    paid: 0,
    iva: 0,
    obervation: "",
    id: 16606,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "Otro",
    method_detail: "",
    concept: "PAGO ADEUDADO OTRO CON CAJA",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 1200,
    paid: 0,
    iva: 0,
    obervation: "",
    id: 16605,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "Efectivo",
    method_detail: "",
    concept: "PAGO ADEUDADO EFECTIVO",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 3500,
    iva: 0,
    obervation: "",
    id: 16604,
    date: "20/09/2024",
    voucher: "Nota Crédito",
    type_number: "B",
    method: "Transf.",
    method_detail: "MERCADO PAGO",
    concept: "PAGO NC TRANSFERENCIA",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 3500,
    iva: 0,
    obervation: "",
    id: 16600,
    date: "20/09/2024",
    voucher: "Nota Crédito",
    type_number: "B",
    method: "Efectivo",
    method_detail: "",
    concept: "PAGO NC EFECTIVO",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 7000,
    iva: 0,
    obervation: "",
    id: 16592,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "Cheque",
    method_detail: "EJEMPLO - 20/09/2024 - 010101",
    concept: "PAGO CHEQUE",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 6000,
    iva: 0,
    obervation: "",
    id: 16591,
    date: "20/09/2024",
    voucher: "Factura",
    type_number: "B",
    method: "Transf.",
    method_detail: "MERCADO PAGO",
    concept: "PAGO TRANSFERENCIA",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 5000,
    iva: 0,
    obervation: "",
    id: 16590,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "TarjetaD",
    method_detail: "DEBITO - 01010101",
    concept: "PAGO TDD",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 4000,
    iva: 0,
    obervation: "",
    id: 16588,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "Tarjeta",
    method_detail: "AMEX - 01010101",
    concept: "PAGO TDC",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 3000,
    iva: 0,
    obervation: "",
    id: 16587,
    date: "20/09/2024",
    voucher: "Factura",
    type_number: "B",
    method: "Otro",
    method_detail: "",
    concept: "PAGO OTRO CON CAJA",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 18711,
    patient: "Maria cristina alvarez altomonte",
    med_ins: "PRIVADOS",
    professional: "AAA  RECETAS PRUEBA",
    owed: 0,
    paid: 1000,
    iva: 0,
    obervation: "",
    id: 16585,
    date: "20/09/2024",
    voucher: "Recibo",
    type_number: "B",
    method: "Efectivo",
    method_detail: "",
    concept: "PAGO EN EFECTIVO",
    is_canceled: false,
    is_usd: false,
    user: "demo",
    office: null,
    type: "example",
  },
  {
    id_patient: 17500,
    patient: "HURTADO DANIELA",
    med_ins: "PRIVADOS",
    professional: "CORTIZO FERNANDEZ AGUSTINA MARIA",
    owed: 0,
    paid: 80000,
    iva: 0,
    obervation: "",
    id: 16577,
    date: "20/09/2024",
    voucher: "Factura",
    type_number: "A",
    method: "Efectivo",
    method_detail: "",
    concept: "PERNO PD 11",
    is_canceled: false,
    is_usd: false,
    user: "rosa.rojas",
    office: null,
    type: "example",
  },
] as const;

export const GEIST_CARD_DATA: Record<
  string,
  {
    title: string;
    description: () => React.ReactNode;
    features: string[];
    image: {
      src: string;
      alt: string;
    };
    buttons: {
      primary: {
        text: string;
        href: string;
      };
      secondary: {
        text: string;
        href: string;
      };
    };
  }
> = {
  transactions: {
    title: "¡Obtén acceso total del modulo!",
    description: () => (
      <p>
        Actualiza tu plan para usar a todas las funciones; con{" "}
        <strong>Movimientos</strong> podrás:
      </p>
    ),
    features: [
      "Ver pagos de pacientes en tiempo real.",
      "Añadir otros ingresos.",
      "Cargar egresos de facturas, proveedores y más.",
      "Buscar movimientos por rango de fechas.",
    ],
    image: {
      src: "/administration/geist-transactions.jpg",
      alt: "Administracion - Movimientos",
    },
    buttons: {
      primary: {
        text: "Contratar ahora",
        href: "#",
      },
      secondary: {
        text: "Mas información",
        href: "https://www.bilog.com.ar",
      },
    },
  },
  banks: {
    title: "¡Obtén acceso total del modulo!",
    description: () => (
      <p>
        Actualiza tu plan para usar a todas las funciones; con{" "}
        <strong>Bancos</strong> podrás:
      </p>
    ),
    features: [
      "Añadir bancos y cuentas.",
      "Registrar movimientos bancarios.",
      "Gestionar movimientos bancarios ilimitados.",
      "Filtrar por moneda local o USD y más.",
    ],
    image: {
      src: "/administration/geist-banks.jpg",
      alt: "Administracion - Bancos",
    },
    buttons: {
      primary: {
        text: "Contratar ahora",
        href: "#",
      },
      secondary: {
        text: "Mas información",
        href: "https://www.bilog.com.ar",
      },
    },
  },
  suppliers: {
    title: "¡Obtén acceso total del modulo!",
    description: () => (
      <p>
        Actualiza tu plan para usar a todas las funciones; con{" "}
        <strong>Proveedores</strong> podrás:
      </p>
    ),
    features: [
      "Ver movimientos de proveedores.",
      "Filtrar por proveedor.",
      "Consultar ovimientos por fecha.",
      "Agrupar importes y comprobantes.",
    ],
    image: {
      src: "/administration/geist-suppliers.jpg",
      alt: "Administracion - Proveedores",
    },
    buttons: {
      primary: {
        text: "Contratar ahora",
        href: "#",
      },
      secondary: {
        text: "Mas información",
        href: "https://www.bilog.com.ar",
      },
    },
  },
  cards: {
    title: "¡Obtén acceso total del modulo!",
    description: () => (
      <p>
        Actualiza tu plan para usar a todas las funciones con{" "}
        <strong>Tarjetas</strong> podrás:
      </p>
    ),
    features: [
      "Registrar tarjetas de débito y crédito.",
      "Añadir cuotas ilimitadas.",
      "Sumar intereses correspondientes.",
    ],
    image: {
      src: "/administration/geist-cards.jpg",
      alt: "Administracion - Tarjetas",
    },
    buttons: {
      primary: {
        text: "Contratar ahora",
        href: "#",
      },
      secondary: {
        text: "Mas información",
        href: "https://www.bilog.com.ar",
      },
    },
  },
} as const;

export const MOVEMENT_TYPES: { [key: string]: string }[] = [
  {
    type: "deposit",
    text: "Depósito en efectivo",
  },
  {
    type: "credit",
    text: "Créditos internos",
  },
  {
    type: "debit",
    text: "Débitos internos",
  },
] as const;

export const CARDS_CHECKBOX_LIST = [
  {
    key: "debit",
    label: "Es tarjeta de débito",
    description: "Las tarjetas de débito no tienen cuotas",
  },
  {
    key: "credit",
    label: "Es tarjeta de crédito",
    description: "Las tarjetas de crédito pueden tener cuotas",
  },
];

export const VOUCHER_DROPDOWN_PAYMENT = [
  {
    voucher: "Otro",
    text: "Otro",
  },
  {
    voucher: "Recibo",
    text: "Recibo",
  },
];

export const VOUCHER_DROPDOWN_OTHER = [
  ...VOUCHER_DROPDOWN_PAYMENT,
  {
    voucher: "Factura",
    text: "Factura",
  },
];

export const VOUCHER_DROPDOWN_INVOICE = [
  ...VOUCHER_DROPDOWN_OTHER,
  {
    voucher: "Nota Débito",
    text: "Nota de débito",
  },
  {
    voucher: "Nota Crédito",
    text: "Nota de crédito",
  },
];

export const VOUCHER_TYPE_DROPDOWN = [
  {
    voucher_type: "A",
    text: "A",
  },
  {
    voucher_type: "B",
    text: "B",
  },
  {
    voucher_type: "C",
    text: "C",
  },
  {
    voucher_type: "X",
    text: "X",
  },
];

export const PAYMENT_METHOD_DROPDOWN = [
  {
    payment_method: "Efectivo",
    text: "Efectivo",
  },
  {
    payment_method: "Transf.",
    text: "Transferencia",
  },
  {
    payment_method: "Otro",
    text: "Otro",
  },
];
